import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccordionGroupCardComponent} from './accordion-group-card.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    AccordionGroupCardComponent
  ],
  imports: [
    CommonModule,
    AccordionModule,
    TranslateModule
  ],
  providers: [],
  exports: [
    AccordionGroupCardComponent
  ]
})

export class ComponentsAccordionGroupCardModule { }
