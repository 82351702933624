import {Component, Input} from '@angular/core';

export interface IAvatarSettings {
  content?: string;
  img?: string;
  class?: string;
}

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

  @Input() settings: IAvatarSettings;

  constructor() { }

}
