<accordion-group class="mb-2" #accordion>
  <div accordion-heading class="d-flex flex-row justify-content-between align-items-center">
    <div class="header-text">
      {{header}}
    </div>
    <i class="fa text-center" [ngClass]="{ 'fa-plus-circle': !accordion.isOpen, 'fa-minus-circle': accordion.isOpen }"></i>
  </div>
  <div class="col-12 mb-2">
    <div class="flex-grow-1 d-flex flex-column">
      <div class="row">
        <div class="color-theme-4 text-left">
          <div class="content-text">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</accordion-group>
