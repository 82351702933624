import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AvatarComponent} from './avatar.component';

@NgModule({
  declarations: [
    AvatarComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [],
  exports: [
    AvatarComponent
  ]
})

export class ComponentsAvatarModule { }
