import {Enum_Componentbadgesactivitybadgedetails_Badgecolour} from '@gen/graphql';
import {CardBanner} from '@components/card/card.component';
import {ActivityDetails} from '@views/activity-details/activity-details.component';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ActivityBannerService {

  constructor(private translate: TranslateService) {
  }

  getActivityBanners(activityDetails: ActivityDetails): CardBanner[] {
    const activityBadge = this.getActivityBadge(activityDetails);
    const couponsBadge = this.getCouponsBadge(activityDetails);

    const banners = [];
    if (activityBadge) {
      banners.push(activityBadge);
    }

    if (couponsBadge) {
      banners.push(couponsBadge);
    }

    return banners;
  }

  private getBadgeClassForBadgeColour(badgeColour?: Enum_Componentbadgesactivitybadgedetails_Badgecolour): string {
    if (!badgeColour) {
      return 'badge-danger';
    }
    switch (badgeColour) {
      case Enum_Componentbadgesactivitybadgedetails_Badgecolour.Blue:
        return 'badge-info';
      case Enum_Componentbadgesactivitybadgedetails_Badgecolour.Green:
        return 'badge-success';
      case Enum_Componentbadgesactivitybadgedetails_Badgecolour.Red:
        return 'badge-danger';
      default:
        return 'badge-danger';
    }
  }

  private hasCoupons(activityDetails: ActivityDetails): boolean {
    return activityDetails.Coupons?.length > 0;
  }

  private getActivityBadge(activityDetails: ActivityDetails): CardBanner {
    const badgeDetails = activityDetails.Badge;
    if (!badgeDetails || !badgeDetails.BadgeText) {
      return null;
    }

    return {
      value: badgeDetails.BadgeText,
      class: this.getBadgeClassForBadgeColour(badgeDetails.BadgeColour)
    };
  }

  private getCouponsBadge(activityDetails: ActivityDetails): CardBanner {
    if (this.hasCoupons(activityDetails)) {
      return {
        value: this.translate.instant('activity-details.exclusive-coupons'),
        class: 'badge-success'
      };
    }
  }

}
