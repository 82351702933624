import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-accordion-group-card',
  templateUrl: './accordion-group-card.component.html',
  styleUrls: ['./accordion-group-card.component.scss']
})
export class AccordionGroupCardComponent {

  @Input() header = '';

  constructor() {
  }

}
