import {Injectable} from '@angular/core';
import {FilesystemDirectory, Plugins} from '@capacitor/core';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {Platform} from '@ionic/angular';
import {Observable, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class OpenDocumentsService {

  constructor(private platform: Platform,
              private fileOpener: FileOpener) { }

  openDocument(document: Blob, documentName: string): Observable<any> {
    const blob = new Blob([document], {type: 'application/pdf'});
    return this.platform.is('hybrid') ? this.openInMobile(blob, documentName) : this.openInBrowser(blob, documentName);
  }

  private openInBrowser(blob: Blob, documentName: string): Observable<any> {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], {type: 'application/pdf'});

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      return of(window.navigator.msSaveOrOpenBlob(newBlob, documentName));
    } else {
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = URL.createObjectURL(newBlob);
      return of(window.open(data, '_blank'));
    }
  }

  private openInMobile(blob: Blob, documentName: string): Observable<any> {
    const openFilePromise = this.blobToBase64(blob)
      .then(documentData =>
        Plugins.Filesystem.writeFile({
          path: documentName,
          data: documentData,
          directory: FilesystemDirectory.Data
        }))
      .then(savedFile => this.fileOpener.open(savedFile.uri, 'application/pdf'));

    return fromPromise(openFilePromise);
  }

  private blobToBase64 = (blob: Blob): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });

}
